import ApexCharts from 'apexcharts';

document.addEventListener('DOMContentLoaded', () => {
    initShare();
});

naja.addEventListener('complete', (event) => {
    const pieElement = document.querySelector('#pie');
    if (pieElement && !pieElement.classList.contains('apexcharts-canvas')) {
        initShare();
    }
});

function initShare() {
    const isShare = document.querySelector('#share');
    if (!isShare) {
        return;
    }

    const share = new Share();
    share.init();
}

class Share {
    constructor() {
        this.chart = null;
    }

    init() {
        const options = {
            chart: {
                width: 450,
                type: "pie",
            },
            labels: [],
            series: [],
            legend: {
                position: "right",
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                width: 0,
            },
        };

        this.chart = new ApexCharts(document.querySelector("#pie"), options);
        this.chart.render();

        this.loadChartData();
    }

    async loadChartData() {
        try {
            const response = await fetch($('#pie').data('link')); // Fetch data from the API
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (data.data.labels && data.data.series) {
                const colors = data.data.colors;

                this.chart.updateOptions({
                    labels: data.data.labels,
                    colors: colors, // Set dynamically generated colors
                });
                this.chart.updateSeries(data.data.series);
            } else {
                console.error('Invalid data from API');
            }
        } catch (error) {
            console.error('Error loading chart data:', error);
        }
    }
}
